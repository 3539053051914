<template>
  <div class="content-wrapper__layout content-wrapper__layout--landing">
    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--1 landing">
      <header class="landing__header">
        <h2 class="landing__header-title">Comparte tus experiencias con nuestra comunidad de líderes y lideresas sociales.</h2>
        <div class="form__actions">
          <router-link :to="{name: $store.state.user.id ? 'Home' : 'Login'}" class="btn btn--primary">{{ $store.state.user.id ? 'Ir al inicio' : 'Regístrate Aquí' }}</router-link>
        </div>
      </header>
      <div class="section">
        <div class="landing-features grid grid-2">
          <div class="landing-feature">
            <img class="feature__image feature__image--pj-yellow" src="@/assets/images/illustrations/ill_pj-yellow.svg">
            <div class="landing-feature__text">
              <p class="feature__description">Encuentra una comunidad de apoyo y aprendizaje de líderes y lideresas sociales para encontrar y aportar respuestas a diversas causas y/o áreas de interés. Comparte y aprende de otros</p>
              <div class="form__actions">
                <router-link :to="{name: 'Home'}" class="btn btn--primary btn--outline">Ver Publicaciones</router-link>
              </div>
            </div>
          </div>
          <div class="landing-feature">
            <img class="feature__image feature__image--pj-blue" src="@/assets/images/illustrations/ill_pj-blue.svg">
            <div class="landing-feature__text">
              <p class="feature__description">Mantente al día y comparte los principales eventos y actividades que pueden ayudar tu labor como lider(esa) social con nuestro calendario compartido</p>
              <div class="form__actions">
                <router-link :to="{name: 'Events'}" class="btn btn--primary btn--outline">Ver Eventos</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="landing-features">
          <h3 class="landing-features-titles">Dorapp es una plataforma abierta para el intercambio y colaboración de conocimiento y experiencias creada por y para líderes y lideresas sociales</h3>

          <div class="example-tags">
            <span class="tag tag--secondary tag--example" v-for="(example, index) in tagExamples" :key="index">
              {{ example.name }}
            </span>
          </div>
          <h5 class="text-center">Y más temas a tratar...</h5>
        </div>
      </div>
      <div class="section">
        <div class="landing-features landig-features--secondary grid grid-4">
          <div class="feature">
            <img class="feature__icon" src="@/assets/images/icons/ico_landing-articles.svg">
            <h5>Agrega publicaciones</h5>
          </div>
          <div class="feature">
            <img class="feature__icon" src="@/assets/images/icons/ico_landing-questions.svg">
            <h5>Publica preguntas</h5>
          </div>
          <div class="feature">
            <img class="feature__icon" src="@/assets/images/icons/ico_landing-events.svg">
            <h5>Busca y publica eventos</h5>
          </div>
          <div class="feature">
            <img class="feature__icon" src="@/assets/images/icons/ico_landing-share.svg">
            <h5>Comparte contenido</h5>
          </div>
        </div>
      </div>
      <div class="section section--image-full">
        <img class="city-image" src="@/assets/images/illustrations/ill_city.png">
      </div>
      <footer class="landing__footer">
        <img class="footer-logo" src="@/assets/images/logo_dorapp--wh.svg">
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tagExamples: [
        {
          name: 'Voluntariado'
        },
        {
          name: 'Valor compartido y sostenibilidad'
        },
        {
          name: 'Gestión de Organizaciones Sociales'
        },
        {
          name: 'Financiamiento y gestión de recursos'
        },
        {
          name: 'Innovación Social'
        },
        {
          name: 'Evaluación Social'
        },
        {
          name: 'Objetivos de Desarrollo Sostenible'
        }
      ]
    }
  }
}
</script>








